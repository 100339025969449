/*
 * @Author: 庄志莹
 * @Date: 2021-03-03 11:43:06
 * @LastEditTime: 2021-03-03 13:37:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\libs\md5.js
 */
let md5 = require('js-md5');
export default {
    md5(password){
        let psd=md5("xiaoniu"+password+"fighting!")
        return psd
    }
}